import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import '../styles/OwnerDashboard.css';
import { useNavigate } from 'react-router-dom';

// Duration options based on starting time
const durationOptionsByStartTime = {
  "12:00 PM": [0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  "12:30 PM": [0.5, 1, 1.5, 2, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5, 10.5, 11.5],
  "1:00 PM": [0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  "1:30 PM": [0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5, 10.5],
  "2:00 PM": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  "3:00 PM": [1, 2, 3, 4, 5, 6, 7, 8, 9],
  "4:00 PM": [1, 2, 3, 4, 5, 6, 7, 8],
  "5:00 PM": [1, 2, 3, 4, 5, 6, 7],
  "6:00 PM": [1, 2, 3, 4, 5, 6],
  "7:00 PM": [1, 2, 3, 4, 5],
  "8:00 PM": [1, 2, 3, 4],
  "9:00 PM": [1, 2, 3],
  "10:00 PM": [1, 2],
  "11:00 PM": [1] // Only shown on Fridays and Saturdays
};


// Function to determine if a date has midnight closing
const isMidnightClosing = (date) => {
  const dayOfWeek = new Date(date).getDay();
  return dayOfWeek === 4 || dayOfWeek === 5; // Friday and Saturday
};


const OwnerDashBoard = () => {
  const [reservations, setReservations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookingForm, setBookingForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    duration: 1,
    simulator: 1,
    lesson: 0,
    groupSize: 1 
  });
  const [editMode, setEditMode] = useState(false);
  const [editingBookingId, setEditingBookingId] = useState(null);
  const [intervals, setIntervals] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [maxDuration, setMaxDuration] = useState(1);
  const [durationOptions, setDurationOptions] = useState([]);

  const navigate = useNavigate();
 
  
  useEffect(() => {
    fetchReservations();
  }, [selectedDate]);
  useEffect(() => {
    if (editMode && bookingForm.time && bookingForm.duration) {
      // Force update dropdown values when entering edit mode with pre-filled values
      setBookingForm((prevForm) => ({
        ...prevForm,
        time: bookingForm.time, // Ensure time is set
        duration: bookingForm.duration, // Ensure duration is set
      }));
    }
  }, [editMode, bookingForm.time, bookingForm.duration]);
  
  useEffect(() => {
    if (bookingForm.date) {
      const dayOfWeek = new Date(bookingForm.date).getDay();
      const availableIntervals = generateHourlyIntervals(dayOfWeek);
      setIntervals(availableIntervals);

      if (bookingForm.time) {
        const combinedDateTime = combineDateTime(bookingForm.date, bookingForm.time);
        const closingTime = getClosingTime(combinedDateTime);

        const availableHours = Math.floor((closingTime - combinedDateTime) / (60 * 60 * 1000));
        setMaxDuration(availableHours > 0 ? availableHours : 1);
      }
    }
  }, [bookingForm.date, bookingForm.time, reservations, bookingForm.simulator]);

  useEffect(() => {
    console.log("Selected date:", moment(selectedDate).format('YYYY-MM-DD'));
    console.log("Reservations:", reservations);
    filterAvailableSlotsForDay(moment(selectedDate).format('YYYY-MM-DD'));
  }, [selectedDate, reservations]);
  

  
  const fetchReservations = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_NGROK}/api/admin/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      
      const today = moment().startOf('day'); // Get today's date
      const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day'); // Get 30 days ago
  
      // Filter bookings to include only:
      // - Bookings within the last 30 days
      // - Today's bookings
      // - Future bookings
      const filteredBookings = data.filter((booking) => {
        const bookingDate = moment(booking.date);
        return bookingDate.isSameOrAfter(thirtyDaysAgo) || bookingDate.isSameOrAfter(today);
      });
  
      setReservations(filteredBookings.sort((a, b) => new Date(a.date) - new Date(b.date)));
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };
  

  const updateDurationOptions = (startTime, date, selectedDuration = null) => {
    let durations = durationOptionsByStartTime[startTime] || [];
    
    if (selectedDuration && !durations.includes(selectedDuration)) {
      durations = [...durations, selectedDuration].sort((a, b) => a - b);
    }
    
    const formattedTime = moment(startTime, ['h:mm A', 'HH:mm']).format('h:mm A'); // Allow both formats
    setBookingForm((prevDetails) => ({
      ...prevDetails,
      time: formattedTime,  // Update form time with formatted string
    }));
    
    console.log("Final durations for dropdown:", durations);
    setDurationOptions(durations);
  };
  
  
  useEffect(() => {
    if (bookingForm.time && bookingForm.date) {
      updateDurationOptions(bookingForm.time, bookingForm.date, editMode ? bookingForm.duration : null);
    }
  }, [bookingForm.time, bookingForm.date]); // Simplified dependencies
  
 
  const generateDurationOptions = () => {
    return durationOptions.map((duration) => (
      <option key={duration} value={duration}>
        {duration} Hour{duration > 1 ? 's' : ''}
      </option>
    ));
  };
  
  const filterAvailableSlotsForDay = (date) => {
    const dayOfWeek = new Date(date).getDay();
    const intervals = generateHourlyIntervals(dayOfWeek);
    console.log("Generated intervals:", intervals);
    
    const bookingsForDate = reservations.filter(
      (booking) => moment(booking.date).format('YYYY-MM-DD') === date
    );
  
    console.log(`Bookings for ${date}:`, bookingsForDate);
  
    const availableSlots = intervals.map((time) => {
      const sim1Available = isSlotAvailable(time, 1, bookingsForDate);
      const sim2Available = isSlotAvailable(time, 2, bookingsForDate);
  
      return { time, sim1Available, sim2Available };
    });
  
    console.log("Available time slots with availability status:", availableSlots);
    setAvailableTimeSlots(availableSlots);
  };
  
  const resetForm = () => {
    setBookingForm({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      date: '',
      time: '',
      duration: 1,
      simulator: 1,
      lesson: 0,
      groupSize: 1
    });
    setEditMode(false);
    setEditingBookingId(null);
  };
  
  const isSlotAvailable = (startTime, simulator, bookingsForDate) => {
    const startMoment = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD h:mm A');
  
    return !bookingsForDate.some((booking) => {
      const bookingStart = moment(booking.date);
      const bookingEnd = moment(bookingStart).add(booking.duration, 'hours');
      return (
        booking.simulator === simulator &&
        startMoment.isBetween(bookingStart, bookingEnd, null, '[)')
      );
    });
  };
  

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    setBookingForm((prevDetails) => ({
      ...prevDetails,
      time: selectedTime,
      duration: 1 // Reset duration on time change
    }));

    // Update duration options directly from the hardcoded list
    setDurationOptions(durationOptionsByStartTime[selectedTime] || []);
  };
  
  const handleCreateBooking = async () => {
    const { firstName, lastName, email, phone, date, time, duration, simulator, lesson, groupSize } = bookingForm;
  
    // Validate input fields
    if (!firstName || !lastName || !email || !phone || !date || !time || !duration || !simulator) {
      alert('Please fill in all required fields.');
      return;
    }
  
    // Check for overlap
    if (isOverlap(date, time, duration, simulator)) {
      alert('The selected time slot overlaps with an existing booking. Please choose another time.');
      return;
    }
  
    // Prepare booking details for submission
    const bookingDetails = {
      firstName,
      lastName,
      email,
      phone,
      date: combineDateTime(date, time).toISOString(),
      duration,
      simulator,
      lesson,
      groupSize
    };
  
    try {
      await createNewBooking(bookingDetails);
      alert('Booking created successfully');
      fetchReservations(); // Refresh the reservation list
      resetForm(); // Clear the form
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  const createNewBooking = async (bookingDetails) => {
    try {
      await axios.post(`${process.env.REACT_APP_NGROK}/api/owner/bookings`, bookingDetails);
      alert('Booking created successfully');
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

 const updateBooking = async (bookingId, bookingDetails) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_NGROK}/api/owner/bookings/${bookingId}`, {
        ...bookingDetails,
        bookingId,
      });
      return response;
    } catch (error) {
      console.error('Error updating booking:', error.response ? error.response.data : error.message);
    }
  };


  
  const isOverlap = (date, time, duration, simulator, currentBookingId = null) => {
    const bookingStart = moment(combineDateTime(date, time));
    const bookingEnd = moment(bookingStart).add(duration, 'hours');
  
    return reservations.some((existingBooking) => {
      // Exclude the current booking when checking for overlap
      if (existingBooking.id === currentBookingId || existingBooking.simulator !== simulator) {
        return false;
      }
  
      const existingStart = moment(existingBooking.date);
      const existingEnd = moment(existingStart).add(existingBooking.duration, 'hours');
  
      return bookingStart.isBefore(existingEnd) && bookingEnd.isAfter(existingStart);
    });
  };
  
  


  const handleUpdateBooking = async () => {
    if (!editingBookingId) {
      console.error('No booking ID available for update');
      return;
    }
  
    const { firstName, lastName, email, phone, date, time, duration, simulator, lesson, groupSize } = bookingForm;
  
    if (isOverlap(date, time, duration, simulator, editingBookingId)) {
      alert('The selected time slot overlaps with an existing booking. Please choose another time.');
      return;
    }
  
    const bookingDetails = {
      firstName,
      lastName,
      email,
      phone,
      date: combineDateTime(date, time).toISOString(),
      duration,
      simulator,
      lesson,
      groupSize
    };
  
    try {
      await updateBooking(editingBookingId, bookingDetails);
      alert('Booking updated successfully');
      fetchReservations(); // Refresh the reservation list
      resetForm(); // Clear the form
    } catch (error) {
      console.error('Error updating booking:', error);
    }
  };
  
  

  const handleEdit = (booking) => {
    console.log("Edit button clicked for:", booking);
  
    const [firstName, ...lastNameParts] = booking.name.split(' ');
    const lastName = lastNameParts.join(' ');
  
    const formattedDate = moment(booking.date).format('YYYY-MM-DD');
    const formattedTime = moment(booking.date).format('h:mm A'); // Ensuring exact format
    
    setEditMode(true);
    setEditingBookingId(booking.id);
  
    // Update bookingForm with the exact time
    setBookingForm({
      firstName: firstName || '',
      lastName: lastName || '',
      email: booking.email || '',
      phone: booking.phone || '',
      date: formattedDate,
      time: formattedTime, // Set the time directly here
      duration: booking.duration || 1,
      simulator: booking.simulator || 1,
      lesson: booking.lesson || 0,
      groupSize: booking.groupSize || 1,
    });
  
    console.log("Prefilled Time (formatted):", formattedTime);
  
    // Directly log duration options to see if the issue comes from here
    updateDurationOptions(formattedTime, formattedDate, booking.duration);
  };
  
  
  
  const handleDelete = async (id) => {
    const token = localStorage.getItem('authToken');
    try {
      await fetch(`${process.env.REACT_APP_NGROK}/api/admin/bookings/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchReservations();
    } catch (error) {
      console.error('Error deleting reservation:', error);
    }
  };

  const getClosingTime = (date) => {
    const closingTime = new Date(date);
    const dayOfWeek = closingTime.getDay();
  
    if (dayOfWeek === 5 || dayOfWeek === 6) {
      closingTime.setHours(24, 0, 0, 0); // Close at midnight
    } else {

      closingTime.setHours(23, 0, 0, 0); // Close at 11 PM

      closingTime.setHours(22, 0, 0, 0);

    }
  
    return closingTime;
  };
  
  const combineDateTime = (date, time) => {
    const [hourMinute, ampm] = time.split(' ');
    let [hour, minute] = hourMinute.split(':');
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);
  
    if (ampm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }
  
    const newDate = new Date(`${date}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`);
    const openingTime = new Date(newDate);
    openingTime.setHours(12, 0, 0, 0); // Open at 12:00 PM
  
    if (newDate < openingTime) {
      throw new Error('Bookings cannot be made before 12:00 PM.');
    }
  
    return newDate;
  };
  
  const generateHourlyIntervals = (dayOfWeek, reservationsForDay = [], simulator, currentBookingTime = null) => {
    const intervals = [];
    const startHour = 12; // Updated opening time to 12 PM
    let endHour;
  
    // Set closing hours based on the day of the week
    if (dayOfWeek === 4 || dayOfWeek === 5) { // Friday and Saturday
      endHour = 24; // Close at midnight
    } else if (dayOfWeek === 0) { // Sunday
      endHour = 22; // Close at 11 PM
    } else {
      endHour = 22; // Close at 11 PM for Monday to Thursday
    }
  
    for (let i = startHour; i < endHour; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const ampm = i < 12 || i === 24 ? 'AM' : 'PM';
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      const fullHourTime = `${formattedHour}:00 ${ampm}`;
      const halfHourTime = `${formattedHour}:30 ${ampm}`;
  
      // Include half-hour intervals before 2 PM
      if (i < 14) {
        intervals.push(fullHourTime);
        intervals.push(halfHourTime);
      } else {
        intervals.push(fullHourTime);
      }
  
      // Always include the current booking time in the list, even if booked
      if (currentBookingTime && intervals.includes(currentBookingTime) === false) {
        intervals.push(currentBookingTime);
      }
    }
  
    return intervals;
  };
  

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/admin');
  };

  return (
    <div className="owner-dashboard admin-dashboard-container">
      <div className="left-section">
        <h1>Owner Dashboard</h1>
        <div className="calendar-container">
        <Calendar
  value={selectedDate}
  onChange={(date) => {
    setSelectedDate(date);
    const formattedDate = moment(date).format('YYYY-MM-DD');
    console.log(`Selected Date: ${formattedDate}`);
    const bookingsForDay = reservations.filter(
      (res) => moment(res.date).format('YYYY-MM-DD') === formattedDate
    );

    // Debugging: Log booking details for the selected day
    console.log(`Bookings for ${formattedDate}:`, bookingsForDay);

    filterAvailableSlotsForDay(formattedDate);
  }}
  tileContent={({ date }) => {
    const dateString = moment(date).format('YYYY-MM-DD');
    const bookingsForDay = reservations.filter((res) =>
      moment(res.date).format('YYYY-MM-DD') === dateString
    );
    return bookingsForDay.length ? <div>{bookingsForDay.length} booking(s)</div> : null;
  }}
/>
        </div>
        <h3>Bookings and Availability for {moment(selectedDate).format('dddd MMM Do YYYY')}</h3>
        <div className="bookings-container">
          <div className="sim-column">
          <div className="simulator-section">
  <h4>Simulator 1</h4>
  {availableTimeSlots.map((slot, index) => {
    // Adjusted to compare slot time with reservation time more precisely
    const bookingsForSlot = reservations.filter((res) => {
      const reservationDateTime = moment(res.date);
      const slotDateTime = moment(selectedDate).set({
        hour: moment(slot.time, 'hh:mm A').hours(),
        minute: moment(slot.time, 'hh:mm A').minutes(),
      });

      return (
        reservationDateTime.isSame(slotDateTime, 'minute') &&
        res.simulator === 1
      );
    });

    console.log(`Bookings for ${slot.time}:`, bookingsForSlot);

    return (
      <div key={index} className={`time-slot ${slot.sim1Available ? 'available' : 'unavailable'}`}>
        {slot.sim1Available ? (
          <p>{slot.time} - Available</p>
        ) : (
          bookingsForSlot.length > 0 ? (
            bookingsForSlot.map((res) => (
              <div className="reservation-card" key={res.id}>
                <p>Name: {res.name} </p>
                <p>Email: {res.email}</p>
                <p>Phone: {res.phone}</p>
                <p>Time: {moment(res.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                <p>Duration: {res.duration} hour(s)</p>
                <p>Lesson: {res.lesson === 0 ? 'None' : res.lesson === 1 ? 'Kids Lesson' : 'Adult Lesson'}</p>
                <p>Group Size: {res.groupSize === 1 ? '1-6' : res.groupSize === 2 ? '6+' : 'Unknown'}</p>

                <p>Status: {res.paid === false ? 'Unpaid' : 'Paid'}</p>
                <div className="reservation-buttons">
                  <button className="edit-btn" onClick={() => handleEdit(res)}>Edit</button>
                  <button className="delete-btn" onClick={() => handleDelete(res.id)}>Delete</button>
                </div>
              </div>
            ))
          ) : (
            <p>{slot.time} - Booked</p> // Fallback if no booking data is found
          )
        )}
      </div>
    );
  })}
</div>






<div className="simulator-section">
<h4>Simulator 2</h4>
  {availableTimeSlots.map((slot, index) => {
    const bookingsForGolfDen = reservations.filter((res) => {
      const reservationDateTime = moment(res.date);
      const slotDateTime = moment(selectedDate).set({
        hour: moment(slot.time, 'hh:mm A').hours(),
        minute: moment(slot.time, 'hh:mm A').minutes(),
      });
      return (
        reservationDateTime.isSame(slotDateTime, 'minute') &&
        res.simulator === 2
      );
    });

    return (
      <div key={index} className={`time-slot ${slot.sim2Available ? 'available' : 'unavailable'}`}>
        {slot.sim2Available ? (
          <p>{slot.time} - Available</p>
        ) : (
          bookingsForGolfDen.length > 0 ? (
            bookingsForGolfDen.map((res) => (
              <div className="reservation-card" key={res.id}>
                
                <p>Name: {res.name}</p>
                <p>Email: {res.email}</p>
                <p>Phone: {res.phone}</p>
                <p>Time: {moment(res.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                <p>Duration: {res.duration} hour(s)</p>
                <p>Lesson: {res.lesson === 0 ? 'None' : res.lesson === 1 ? 'Kids Lesson' : 'Adult Lesson'}</p>
                <p>Group Size: {res.groupSize === 1 ? '1-6' : '6+'}</p>
                <p>Status: {res.paid === false ? 'Unpaid' : 'Paid'}</p>
                <div className="reservation-buttons">
                  <button className="edit-btn" onClick={() => handleEdit(res)}>Edit</button>
                  <button className="delete-btn" onClick={() => handleDelete(res.id)}>Delete</button>
                </div>
              </div>
            ))
          ) : (
            <p>{slot.time} - Booked</p>
          )
        )}
      </div>
    );
  })}
</div>

          </div>
        </div>
      </div>
  
      <div className="right-section">
        <h3>{editMode ? 'Edit Booking' : 'Create Booking'}</h3>
        <div className="form-container">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={bookingForm.firstName}
            onChange={(e) => setBookingForm({ ...bookingForm, firstName: e.target.value })}
            className="custom-input"
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={bookingForm.lastName}
            onChange={(e) => setBookingForm({ ...bookingForm, lastName: e.target.value })}
            className="custom-input"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={bookingForm.email}
            onChange={(e) => setBookingForm({ ...bookingForm, email: e.target.value })}
            className="custom-input"
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={bookingForm.phone}
            onChange={(e) => setBookingForm({ ...bookingForm, phone: e.target.value })}
            className="custom-input"
          />
          <input
            type="date"
            name="date"
            value={bookingForm.date}
            onChange={(e) => setBookingForm({ ...bookingForm, date: e.target.value })}
            className="custom-datepicker"
          />
<select
  name="time"
  value={bookingForm.time || ''}  // Explicitly set to empty string if null/undefined
  onChange={handleTimeChange}
>
  <option value="">Select Time</option>
  {Object.keys(durationOptionsByStartTime).map((time) => (
    <option key={time} value={time}>
      {time}
    </option>
  ))}
</select>


        <select
          name="duration"
          value={bookingForm.duration}
          onChange={(e) => setBookingForm({ ...bookingForm, duration: parseFloat(e.target.value) })}
        >
          <option value="">Select Duration</option>
          {generateDurationOptions()}
        </select>
          <select
            name="simulator"
            value={bookingForm.simulator}
            onChange={(e) => setBookingForm({ ...bookingForm, simulator: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={1}>Simulator 1</option>
            <option value={2}>Simulator 2</option>
          </select>
          <select
            name="groupSize"
            value={bookingForm.groupSize}
            onChange={(e) => setBookingForm({ ...bookingForm, groupSize: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={1}>1-6</option>
            <option value={2}>6+</option>
          </select>
          <select
            name="lesson"
            value={bookingForm.lesson}
            onChange={(e) => setBookingForm({ ...bookingForm, lesson: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={0}>None</option>
            <option value={1}>Kids Lesson</option>
            <option value={2}>Adult Lesson</option>
          </select>
          <button className="create-btn" onClick={editMode ? handleUpdateBooking : handleCreateBooking}>
            {editMode ? 'Update Booking' : 'Create Booking'}
          </button>
          {editMode && (
            <button className="cancel-btn" onClick={resetForm}>Cancel Edit</button>
          )}
        </div>
        <button className="logout-btn" onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
  
};

export default OwnerDashBoard;
